import React, { useState, useRef } from "react";
import { Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure, Spinner, Input } from "@nextui-org/react";
import ReactToPrint from 'react-to-print';

export default function PrintOkayModal({ data , allpay, too}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const componentRef = useRef(null);

  const subchck = () => {
    console.log("S")
    onClose();
  }

  return (
    <>
      <Button onClick={onOpen}>Хэвлэх</Button>
      <Modal isOpen={isOpen} onClose={onClose} scrollBehavior='inside' size="md">
        <ModalContent>
          <ModalBody>
            <div
              ref={componentRef}
              className='border-2 font-Roboto px-10 py-4'
              style={{
                width: '80mm',
                height: '120mm',
              }}
            >
              
              <div>
                <h1 className="text-xs">Хэрэглэгчийн утасны дугаар:</h1>
                <p className="font-bold text-sm">{data.user.phone_no}</p>
                <h1 className="text-xs mt-2">Нэмэлт утасны дугаар:</h1>
                <p className="font-bold text-sm">{data.phone_additional}</p>

                <h1 className="text-xs mt-2">Албан хаяг</h1>
                <p className="font-bold text-sm">{data.address.city}, {data.address.district}, {data.address.khoroo}</p>

                <h1 className="text-xs mt-2">Дэлгэрэнгүй хаяг</h1>
                <p className="font-bold text-sm">{data.address.address}</p>

                <h1 className="text-xs mt-2">Барааны тоо</h1>
                <p className="font-bold text-sm">{too}</p>

                <h1 className="text-xs mt-2">Карго төлбөр</h1>
                <p className="font-bold text-sm">{allpay}</p>

                <h1 className="text-center mt-4 font-bold text-sm">Best каргог сонгодог танд баярлалаа</h1>
              </div>

            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="danger" variant="light" onClick={onClose}>
              Болих
            </Button>
              <ReactToPrint
                trigger={() => (
                  <Button className=" bg-blue-600 text-white" onClick={subchck}>
                    Хэвлэх
                  </Button>
                )}
                content={() => componentRef.current}
                pageStyle={`
                  @page {
                    size: A7;
                    margin: 0;
                  }
                  @media print {
                    body {
                      width: 74mm;
                      height: 105mm;
                      margin: 0;
                    }
                  }
                `}
              />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}