import React, { useEffect, useState } from 'react'
import OldCodeExcel from '../../../components/Excel/old'

const Home = () => {

  return (
    <div className='bg-white rounded-md p-4'>
      <div className='flex justify-between items-center'>
        <h1>
        Best карго админ хуудас
        </h1>
        <div className='flex items-center gap-2'>
          <h1>Хуучин код оруулах</h1>
          <OldCodeExcel/>
        </div>
      </div>
    </div>
  )
}




export default Home

