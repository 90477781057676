import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Input, Spinner } from '@nextui-org/react'
import { GET, POST } from '../../../utils/requests';
import Paginations from '../../../components/Pagination';
import { delivery_uri, order_uri } from '../../../utils/url';
import {TYPES} from '../../../utils/types'
import { toast } from 'react-toastify';
import moment from "moment";
import ReqDetailModal from './Modals/reqDetails';


const DeliveryRequest = () => {
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([])
  const [pagination, setPagination] = useState({limit:15, all:'', totalPage:'',phone_no:''});
  const [pay, setPay] = useState({onepay:'', allpay:''});
  const [page, setPage] = useState(1);
  const [checkedValue, setValue] = useState([]);
  const [checkedTracks, setCheckedTracks] = useState([]);
  const [checkload, setCheckload] = useState(false);

  useEffect(() => {
    Get();
  }, [page]);

  const Get = async () => {
    setLoad(true);
    const b = await GET(delivery_uri+`/all?page=${page}&limit=${pagination.limit}&status=${TYPES.REQUESTED}&phone_no=${pagination.phone_no}`);
    setData(b.data.data);
    setPagination({...pagination, all:b.data.all, totalPage:b.data.totalPage});
    setLoad(false);
  };


  const callback =()=> {
    Get();
  }
  
  const changePage = (page) => {
    setPage(page);
  };

  function handleChange(event){
    const {value, checked} = event.target
    const a = checkedValue.includes(value);
    
    if(a){
      return
    }
      if(checked){
        setValue(pre => [...pre, value])
      }else{
        setValue(pre => {
          return [...pre.filter(skill => skill!==value)];
        })
      }
  }
  
  const handleCheckClick = (item) => {
    setCheckedTracks((prevCheckedTracks) => [...prevCheckedTracks, item]);
    setData((prevData) => prevData.filter((dataItem) => dataItem.id !== item.id));
  };

  const handleDeleteClick = (item) => {
    const track = data.filter(it => it.id === item.id);
    if(track.length > 0){
      setCheckedTracks((prevData) => prevData.filter((dataItem) => dataItem.id !== item.id));
    }else{
      setCheckedTracks((prevData) => prevData.filter((dataItem) => dataItem.id !== item.id));
      setData((prevCheckedTracks) => [...prevCheckedTracks, item]);
    }
  };

  const calculateTotalPayment = () => {
    return checkedTracks.reduce((total, track) => total + parseFloat(track.payment), 0);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      Get()
    }
  };

  const isItemChecked = (itemId) => {
    return checkedValue.includes(itemId);
  };

  return (
    <div>
      <div>

        <div className="mr-2 mt-2 font-Roboto">
          <div className="overflow-x-auto shadow-md sm:rounded-md grid col-span-5">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden ">
                <div className='bg-[#080852] rounded-md my-2 p-4 flex justify-between items-center text-white'>
                  <h1 className='text-lg font-bold'>Бүртгүүлсэн хүргэлтүүд</h1>
                </div>
              <div className='bg-white p-4'>
                <div className='flex items-center gap-2'>
                  <input onKeyDown={handleKeyDown} size='' className='border rounded-lg mt-2 focus:border-1 focus:border-blue-600 outline-none w-full p-2 bg-gray-100' placeholder='Хайлт хийх' onChange={(e) => setPagination({...pagination, phone_no:e.target.value})}/>
                 
                </div>
              {/* <div className='mt-2'>
                <AllPayModal data={checkedValue} callback={callback}/>
              </div> */}
              </div>
                <table className="min-w-full divide-y divide-gray-200 table-fixed dark:divide-gray-700">
                  <thead className="bg-gray-50 dark:bg-gray-700">
                    <tr>
                      {/* <th scope="col" className="p-4 text-sm font-normal">
                      </th> */}
                      <th scope="col" className="p-4 text-sm font-normal">
                        #
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                        Утас
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                        Хот/Аймаг
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                        Дүүрэг/Сум
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                        Баг/Хороо
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                        Дэлгэрэнгүй хаяг
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                        Нэмэлт утас
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                       Бүртгүүлэсэн огноо
                      </th>
                      <th scope="col" className="py-2 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400">
                      </th>
                    </tr>
                  </thead>
                  {
                    load?
                    <div className='flex items-center justify-center  p-4'>
                      <Spinner/>
                      <h1 className=''>Уншиж байна ...</h1>
                    </div>
                    :
                    <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                      {
                        data.map((item, index) => {
                          return(
                            <tr className="hover:bg-gray-100 dark:hover:bg-gray-700" key={index}>
                                <td className="p-2 w-4 text-center">
                                  {index+1}
                                </td>
                                <td className="py-2 px-6 text-sm font-medium text-gray-900 whitespace-nowrap dark:text-white flex flex-col">
                                  <label className=''>{item.user.phone_no}</label>
                                </td>
                                <td className="py-2 px-6 text-sm font-medium text-gray-500  dark:text-white text-overflow">{item.address.city}</td>
                                <td className="py-2 px-6 text-sm font-medium text-gray-500  dark:text-white text-overflow">{item.address.district}</td>
                                <td className="py-2 px-6 text-sm font-medium text-gray-500  dark:text-white text-overflow">{item.address.khoroo}</td>
                                <td className="py-2 px-6 text-sm font-medium text-gray-500  dark:text-white text-overflow">{item.address.address}</td>
                                <td className="py-2 px-6 text-sm font-medium text-gray-500  dark:text-white text-overflow">{item.phone_additional}</td>
                                <td className="py-2 px-6 text-sm font-medium text-gray-500  dark:text-white text-overflow">{moment(item.created_date).format('YYYY-MM-DD HH:mm:ss')}</td>
                                <td className="py-2 px-6 text-sm font-medium text-gray-500  dark:text-white text-overflow flex gap-2">
                                  <ReqDetailModal callback={callback} data={item}/>
                                </td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  }
                </table>
                {
                  data.length === 0 &&
                  <div className='bg-white pt-8 text-center flex items-center justify-center flex-col'>
                    <img className='h-24' src='../../empty.gif'/>
                    <h1 className=''>Хоосон байна...</h1>
                  </div>
                }
               <Paginations initialPage={page} total={pagination.totalPage} alldata={pagination.all} onChange={changePage}/>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default DeliveryRequest


